<template>
	<resource-form :id="id" type="video" plural="videos" v-model="video"></resource-form>
</template>

<script>

import validationRules from "@/util/validation";
import {sync} from "vuex-pathify";
import resourceForm from '@/views/resources/Form'
import models from '@/models'

export default {
	name: "VideosForm",
	components: {resourceForm},
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		video: sync('videos/video'),
	},
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.$store.set('topics/getTopics!', null)
			if (this.isEdit) {
				this.$store.set('videos/getVideo!', this.id)
			} else {
				this.video = new models.Resource('Video')
			}
		}
	}
}
</script>